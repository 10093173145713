.page {
  background: url("../../images/grid_futures_share_bg.png") #1d1e21;
  background-size: 43px;
}

.mobileContent {
  margin: auto 12px;
  gap: 12px;
  display: flex;
  flex-direction: column;
  padding-bottom: 150px;
}

.normalContent {
  width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.logoBox {
  padding-top: 90px;
  padding-bottom: 40px;
  text-align: center;
}

.logoIcon {
  width: 152px;
}

.orderClosedTipsBox {
  border-radius: 8px;
  background-color: #3B2920;
  padding: 12px;
  gap: 8px;
  display: flex;
  align-items: flex-start;
}

.orderClosedTipsText {
  color: #FF4F29;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

.orderClosedTipsIcon {
  width: 16px;
  height: 16px;
  color: #FF4F29;
}

.infoCard {
  border-radius: 12px;
  padding: 24px;
  background:
    url("../../images/bg_line.png") no-repeat left top,
    linear-gradient(to right, #222231, #4b4c50);
  background-size:
    100% 100%,
    100%;
  border: 0.5px solid #585454;
}

.infoCardSplit {
  margin: 8px 0;
  height: 15px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.infoCardSplit::before,
.infoCardSplit::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 7.5px;
  height: 15px;
  width: 15px;
  background-color: #1d1e21;
  top: 0;
}

.infoCardSplit::before {
  left: -7.5px;
  margin-left: -24px;
}

.infoCardSplit::after {
  right: -7.5px;
  margin-right: -24px;
}

.infoCardSplitLine {
  width: 100%;
  height: 0;
  border-top: 1px dashed #f2f4f7;
  opacity: 0.2;
}

.infoCardHeader {
  display: flex;
  flex-direction: row;
}

.kolAvatar {
  height: 46px;
  width: 46px;
  border-radius: 50%;
  border: 1.5px solid #fff;
  margin-right: 18px;
}

.botInfoTitle {
  font-size: 18px;
  line-height: 21px;
  color: #ffeac7;
  margin-bottom: 8px;
  font-weight: 600;
}

.botInfoInner {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  color: #ffeac7;
  font-size: 14px;
  line-height: 16px;
}

.botInfoSplit {
  height: 5.5px;
  width: 0;
  border-left: 1px solid #efd7b0;
}

.infoCardFooter {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 14px;
  color: #ffeac7;
  line-height: 18px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.infoLabel {
  opacity: 0.6;
}

.infoValue {
  font-weight: 500;
}

.profitBox {
  background: linear-gradient(to right, #222231, #4b4c50);
  padding: 24px;
  border-radius: 12px;
  border: 0.5px solid #585454;
}

.gridProfitBox {
  text-align: center;
  margin-bottom: 21px;
}

.gridProfitBoxTitle {
  color: #fff;
  font-size: 14px;
  line-height: 16px;
}

.gridProfitBoxValue {
  font-weight: 900;
  font-size: 50px;
  line-height: 58px;
}

.profitBoxBottom {
  display: flex;
  font-size: 14px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
  flex-direction: row;
  justify-content: space-between;
}

.profitBoxBottom > div {
  width: 33%;
  text-align: center;
}

.profitBoxBottom > div:first-child {
  text-align: left;
}

.profitBoxBottom > div:last-child {
  text-align: right;
}

.createOrderBtnBase {
  height: 46px;
  border-radius: 6px;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 6px;
}

/* 通过@keyframes规则,能够创建动画 , que 定义动画的名称 可自己定义 */
@keyframes loading {
  /* 以百分比来规定改变发生的时间 也可以通过"from"和"to",等价于0% 和 100% */
  0% {
    /* rotate(2D旋转) scale(放大或者缩小) translate(移动) skew(翻转) */
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loadingIcon {
  height: 16px;
  width: 16px;

  /* animation (动画) :绑定选择器, 4s完成动画 linear(匀速) infinite(循环) */
  animation: loading 1.5s linear infinite;
}

.createOrderBtn {
  background-image: linear-gradient(to right, #ff6e30, #ff4471);
  color: #fff;
}

.createOrderBtnDisable {
  background-color: rgba(57, 60, 67, 1);
  color: rgba(255, 255, 255, 0.3);
}

.privateTip {
  opacity: 0.5;
  color: #fff;
  display: flex;
  justify-content: center;
}

.footer {
  padding: 16px 30px;
  border-radius: 12px;
  border: 1px solid rgba(217, 217, 217, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.kolShareCodeTitle {
  font-size: 14px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 4px;
}

.kolShareCode {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #ffeac7;
}

.qrCodeText {
  font-size: 10px;
  line-height: 14px;
  color: #fff;
}



@media (width < 768px) {
  .logoBox {
    padding-top: 44px;
    padding-bottom: 24px;
    text-align: center;
  }

  .logoIcon {
    width: 107px;
  }

  .infoCard {
    padding: 16px;
  }

  .infoCardSplit {
    margin: 4px 0;
    height: 9px;
  }

  .infoCardSplit::before,
  .infoCardSplit::after {
    height: 9px;
    width: 9px;
    border-radius: 4.5px;
  }

  .infoCardSplit::before {
    left: -4.5px;
    margin-left: -16px;
  }

  .infoCardSplit::after {
    right: -4.5px;
    margin-right: -16px;
  }

  .kolAvatar {
    height: 38px;
    width: 38px;
  }

  .botInfoTitle {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 6px;
  }

  .botInfoInner {
    font-size: 12px;
    line-height: 14px;
  }

  .infoCardFooter {
    gap: 6px;
    font-size: 14px;
    line-height: 20px;
  }

  .profitBox {
    padding: 16px;
  }

  .gridProfitBox {
    text-align: left;
    margin-bottom: 6px;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  .profitBoxBottomMobile {
    font-size: 14px;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.5);
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    border: none;
    border-top: 1px solid rgba(217, 217, 217, 0.2);
    border-radius: 0;
    margin: 0 -12px;
    background: #1d1e21;
  }

  .qrCodeBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }
}


.pionexModalContaner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background: #1A1B1E;
  padding: 0 24px 24px;
}

.pionexModalContanerTitle {
  color: #F4F4F5;
  font-weight: 500;
  font-size: 20px;
  height: 73px;
  line-height: 73px;
  text-align: center;
}

.pionexModalContanerContent {
  color: #F4F4F5;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin-left: 0 ;
  margin-bottom: 32px;
}

.pionexModalContanerFooter{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
}

.pionexModalContanerFooterBtn {
  font-size: 16px;
  font-weight: 600;
  border-radius: 6px;
  height: 38px;
  line-height: 38px;
  flex: 1;
  text-align: center;
  color: #F4F4F5;
  cursor: pointer;
}

.pionexModalContanerFooterOkBtn {
  background-color: #FF4F29;
  border-color: #ff7028;
  border-radius: 8px;
  
}

.pionexModalContanerFooterCancelBtn {
  background-color: transparent;
  border: 1px solid #FF4F29;
  color: #FF4F29;
}

@media (width < 768px) {
  .pionexModalContaner {
    position: fixed;
    bottom: 0;
    left: 0;
    background: #333;
    width: 100%;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    padding-bottom: 34px;
    transform: none;
    top: auto;
  }

  .pionexModalContanerFooterCancelBtn {
    background-color: #3F4046;
    border-color: #FF4F29;
    color: #FF4F29;
    border: none;
  }
}