
.mobileContent {
  margin: auto 12px;
  gap: 12px;
  display: flex;
  flex-direction: column;
  padding-bottom: 150px;
}

.normalContent {
  width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.createOrderBtnBase {
  height: 46px;
  border-radius: 6px;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 6px;
}

.logoBox {
  padding-top: 90px;
  padding-bottom: 40px;
  text-align: center;
}

/* 通过@keyframes规则,能够创建动画 , que 定义动画的名称 可自己定义 */
@keyframes loading {
  /* 以百分比来规定改变发生的时间 也可以通过"from"和"to",等价于0% 和 100% */
  0% {
    /* rotate(2D旋转) scale(放大或者缩小) translate(移动) skew(翻转) */
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loadingIcon {
  height: 16px;
  width: 16px;

  /* animation (动画) :绑定选择器, 4s完成动画 linear(匀速) infinite(循环) */
  animation: loading 1.5s linear infinite;
}

.createOrderBtn {
  background-image: linear-gradient(to right, #ff6e30, #ff4471);
  color: #fff;
}

.createOrderBtnDisable {
  background-color: rgba(57, 60, 67, 1);
  color: rgba(255, 255, 255, 0.3);
}

.privateTip {
  opacity: 0.5;
  color: #fff;
  display: flex;
  justify-content: center;
}
