.profitBox {
  background: linear-gradient(to right, #222231, #4b4c50);
  padding: 24px;
  border-radius: 12px;
  border: 0.5px solid #585454;
}

.gridProfitBox {
  text-align: center;
  margin-bottom: 21px;
}

.gridProfitBoxTitle {
  color: #fff;
  font-size: 14px;
  line-height: 16px;
}

.gridProfitBoxValue {
  font-weight: 900;
  font-size: 50px;
  line-height: 58px;
}

.gridProfitBoxStatus {
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  color: #fff;
}

.webProfitContainerSingleLine{
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.webProfitContainerMultiLine{
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.profitBoxBottom {
  display: flex;
  font-size: 14px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
  flex-direction: row;
  flex-direction: flex-start;
  justify-content: space-between;
}

.profitBoxItem {
  width: 33%;
  text-align: center;
}

.profitBoxItem:only-child {
  width: 100%;
}

.profitBoxItem:first-child:not(:only-child) {
  text-align: left;
}

.webProfitContainerMultiLine .profitBoxItem:first-child{
  text-align: left !important;
}

.profitBoxItem:last-child:not(:only-child) {
  text-align: right;
}

@media (width < 768px) {
  .profitBox {
    padding: 16px;
  }

  .gridProfitBox {
    text-align: left;
    margin-bottom: 6px;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  .profitBoxBottomMobile {
    font-size: 14px;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.5);
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
