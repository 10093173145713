.page {
  background-color: #f4f4f5;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 100%;
  padding: 16px;
  background-color: #fafafa;
  margin-bottom: 12px;
}

.card {
  width: 351px;
  background-color: #fafafa;
  border-radius: 12px;
  padding: 16px;
  display: grid;
  grid-template-rows: auto auto auto;
  gap: 12px;
}

.chart-area {
  height: 180px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
}

.chart {
  width: 100%;
  height: 80%;

  /* background-color: yellowgreen; */

  display: flex;
  justify-content: center;
  align-items: center;
}

.legend {
  align-self: stretch;
}

@media (width >= 768px) {
  .page {
    padding-top: 20vh;
  }

  .logo {
    width: max-content;
    padding: 16px;
    background: transparent;
    margin-bottom: 12px;
  }

  .card {
    width: 748px;
    padding: 24px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
  }

  .chart-area {
    width: 100%;
    height: 100%;
    grid-column: 2;
    grid-row: 1 / span 2;
    border-left: #e9e9ea solid 1px;
    padding: 16px;
  }

  .share {
    grid-column: 1;
    grid-row: 2;
  }
}
