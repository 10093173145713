.orderClosedTipsBox {
  border-radius: 8px;
  background-color: #3B2920;
  padding: 12px;
  gap: 8px;
  display: flex;
  align-items: flex-start;
}

.orderClosedTipsText {
  color: #FF4F29;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

.orderClosedTipsIcon {
  width: 16px;
  height: 16px;
  color: #FF4F29;
}