.subTitleTagsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  color: #ffeac7;
  font-size: 14px;
  line-height: 16px;
}

.split {
  height: 5.5px;
  width: 0;
  border-left: 1px solid #efd7b0;
}
