.coin-list {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 8px;
  align-items: center;
}

.coin-list .coin-item{
  display: flex;
  gap: 8px;
  align-items: center;
}

/* stylelint-disable-next-line media-feature-range-notation */
@media screen and (max-width: 768px) {
  .coin-list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}