.wrapper_card{
    padding:24px;
    background-color:#fff;
    border-radius:12px;
    margin:0 12px;
}
.first_row{
    display:flex;
    justify-content: space-between;
}
.first_field{
    display:flex;
    flex-direction: column;
    gap:8px;
}
.first_label{
    position:relative;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color:#3F4046;
}
.first_value{
    font-size: 40px;
    font-weight: 700;
    line-height: 47px;
}
.first_unit{
    margin-left:2px;
    position: relative;
    top:-6px;
    font-size: 8px;
    font-weight: 400;
    line-height: 11px;

}
.first_percent{
    font-size: 20px;
    line-height: 23px;
}
.text_increase{
    color: rgb(10, 191, 127);
}
.text_decrease{
    color:rgb(235, 71, 71);
}
.items_end{
    align-items:flex-end;
}
.second_row{
    margin-top:24px;
    line-height:18px;
    font-size:12px;
}
.second_label{
    color: #71737A;
}
.second_value{
    margin-left:4px;
    color: #27282A;
    font-weight:500;
}
.grid_fields{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top:24px;
}
.grid_fields_group{
    display: flex;
    flex-direction: column;
    gap:4px;
}
.grid_fields_label{
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color:#3F4046;
}
.grid_fields_value{
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;

}
.timestamp_text{
    margin-top:24px;
    border-top: 0.5px solid #E9E9EA;
    padding-top:12px;
    font-size: 12px;
    line-height:18px;
    color: #A1A2AA;

}
@media screen and (max-width: 768px) {
    .wrapper_card{
        padding: 12px;
    }
    .first_field{
        gap:2px;
    }
    .first_label{
        font-size: 12px;
        line-height: 16.8px;
    }
    .first_value,.first_percent{
        font-size:24px;
        line-height:30px;
    }
    .second_row{
        margin-top:16px;
    }
    .grid_fields{
        margin-top:16px;
    }
    .timestamp_text{
        margin-top:16px;
    }
    .grid_fields_value{
        font-size: 12px;
        line-height: 18px;
    }
}