.infoCard {
  border-radius: 12px;
  padding: 24px;
  background:
    url("../../images/bg_line.png") no-repeat left top,
    linear-gradient(to right, #222231, #4b4c50);
  background-size:
    100% 100%,
    100%;
  border: 0.5px solid #585454;
}

.infoCardSplit {
  margin: 8px 0;
  height: 15px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.infoCardSplit::before,
.infoCardSplit::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 7.5px;
  height: 15px;
  width: 15px;
  background-color: #1d1e21;
  top: 0;
}

.infoCardSplit::before {
  left: -7.5px;
  margin-left: -24px;
}

.infoCardSplit::after {
  right: -7.5px;
  margin-right: -24px;
}

.infoCardSplitLine {
  width: 100%;
  height: 0;
  border-top: 1px dashed #f2f4f7;
  opacity: 0.2;
}

.infoCardHeader {
  display: flex;
  flex-direction: row;
}

.kolAvatar {
  height: 46px;
  width: 46px;
  border-radius: 50%;
  border: 1.5px solid #fff;
  margin-right: 18px;
}

.botInfoTitle {
  font-size: 18px;
  line-height: 21px;
  color: #ffeac7;
  margin-bottom: 8px;
  font-weight: 600;
}

.botInfoInner {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  color: #ffeac7;
  font-size: 14px;
  line-height: 16px;
}

.infoCardFooter {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 14px;
  color: #ffeac7;
  line-height: 18px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.infoLabel {
  opacity: 0.6;
}

.infoValue {
  font-weight: 500;
}

@media (width < 768px) {
  .infoCard {
    padding: 16px;
  }

  .infoCardSplit {
    margin: 6px 0;
    height: 9px;
  }

  .infoCardSplit::before,
  .infoCardSplit::after {
    height: 9px;
    width: 9px;
    border-radius: 4.5px;
  }

  .infoCardSplit::before {
    left: -4.5px;
    margin-left: -16px;
  }

  .infoCardSplit::after {
    right: -4.5px;
    margin-right: -16px;
  }

  .kolAvatar {
    height: 38px;
    width: 38px;
  }

  .botInfoTitle {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 6px;
  }

  .botInfoInner {
    font-size: 12px;
    line-height: 14px;
  }

  .infoCardFooter {
    gap: 6px;
    font-size: 14px;
    line-height: 18px;
  }
}
