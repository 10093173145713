.page {
  background: url("../../images/grid_futures_share_bg.png") #1d1e21;
  background-size: 43px;
}

.mobileContent {
  margin: auto 12px;
  gap: 12px;
  display: flex;
  flex-direction: column;
  padding-bottom: 150px;
}

.infoLine {
  width: 1px;
  height: 5px;
  background-color: #ffeac7;
}

.normalContent {
  width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.logoBox {
  padding-top: 90px;
  padding-bottom: 16px;
  text-align: center;
}

.logoIcon {
  width: 152px;
}

.orderClosedTipsBox {
  border-radius: 8px;
  background-color: #3b2920;
  padding: 10px 12px;
  gap: 8px;
  display: flex;
  align-items: center;
}

.orderClosedTipsText {
  color: #ff4f29;
  font-size: 12px;
  line-height: 16px;
}

.orderClosedTipsIcon {
  width: 16px;
  height: 16px;
}

.createOrderBtnBase {
  height: 46px;
  border-radius: 6px;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 6px;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loadingIcon {
  height: 16px;
  width: 16px;
  animation: loading 1.5s linear infinite;
}

.createOrderBtn {
  background-image: linear-gradient(to right, #ff6e30, #ff4471);
  color: #fff;
}

.createOrderBtnDisable {
  background-color: rgba(57, 60, 67, 1);
  color: rgba(255, 255, 255, 0.3);
}

@media (width < 768px) {
  .logoBox {
    padding-top: 44px;
    padding-bottom: 12px;
    text-align: center;
  }

  .logoIcon {
    width: 107px;
  }
}
