.footer {
  margin-top: 24px;
  padding: 16px 30px;
  border-radius: 12px;
  border: 1px solid rgba(217, 217, 217, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #1d1e21;
}

.kolShareCodeTitle {
  font-size: 14px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 4px;
}

.kolShareCode {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #ffeac7;
}

.qrCodeText {
  font-size: 10px;
  line-height: 14px;
  color: #fff;
}

@media (width < 768px) {
  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    border: none;
    border-top: 1px solid rgba(217, 217, 217, 0.2);
    border-radius: 0;
    margin: 0 -12px;
  }

  .qrCodeBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }
}
